<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box dark:bg-dark-8 p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="iam.chefDetails.kitchen.edit"
              v-if="isEditing"
            ></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading || !model"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-3"
                >
                  {{ i18n('iam.chefDetails.kitchen.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.kitchenName.en"
                    :placeholder="i18n('common.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.kitchenName.ar"
                    :placeholder="i18n('common.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-3"
                >
                  {{ i18n('iam.chefDetails.kitchen.categories') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <TomSelect
                    v-model="model.categoriesIds"
                    class="!w-full"
                    multiple
                    :maxItems="3"
                  >
                    <option
                      v-for="(category, index) in kitchenCategories"
                      :key="index"
                      :value="category.id"
                    >
                      {{ presenterLocalization(category, 'name') }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-3"
                >
                  {{ i18n('iam.chefDetails.kitchen.minOrder') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="number"
                    step="0.01"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.minOrderAmount"
                    :placeholder="i18n('iam.chefDetails.kitchen.minOrder')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-3"
                >
                  {{ i18n('iam.chefDetails.kitchen.schedule') }}
                </h4>
                <div
                  class="lg:col-span-8 col-span-12 lg:mt-0 mt-2 grid gap-y-2 items-center"
                >
                  <div
                    class="grid grid-cols-12 relative gap-y-3"
                    v-for="(day, index) in model.schedule"
                    :key="`schedule-${index}`"
                  >
                    <div
                      class="grid grid-cols-12 lg:col-span-12 col-span-12 lg:mt-0 mt-2 items-center"
                    >
                      <h4
                        class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-6"
                      >
                        {{ presenterDayLocalize(index) }}
                      </h4>
                      <div class="col-span-6 flex items-center justify-end">
                        <input
                          type="checkbox"
                          v-model="day.working"
                          @click="resetField(index)"
                          :id="`switch-${index}`"
                        /><label :for="`switch-${index}`">Toggle</label>
                      </div>
                    </div>
                    <div class="lg:col-span-6 col-span-12 lg:mt-0 mt-2">
                      <input
                        id="crud-form-1"
                        type="time"
                        class="form-control w-full dark:placeholder-white"
                        v-model="day.startTime"
                        :placeholder="i18n('common.english')"
                      />
                    </div>
                    <div
                      class="lg:col-span-6 col-span-12 lg:mt-0 mt-2"
                      :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                    >
                      <input
                        id="crud-form-1"
                        type="time"
                        class="form-control w-full dark:placeholder-white"
                        v-model="day.endTime"
                        :placeholder="i18n('common.arabic')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-3"
                >
                  {{ i18n('iam.chefDetails.kitchen.logo') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && logoUrl">
                    <img
                      :src="logoUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.logo = undefined"
                    />
                  </div>
                  <Uploader
                    ref="uploaderLogo"
                    :path="storagePath"
                    type="image/*"
                    v-else
                  ></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-6 lg:col-span-3"
                >
                  {{ i18n('iam.chefDetails.kitchen.cover') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && coverUrl">
                    <img
                      :src="coverUrl"
                      class="object-cover object-center"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.cover = undefined"
                    />
                  </div>
                  <Uploader
                    ref="uploaderCover"
                    :path="storagePath"
                    type="image/*"
                    v-else
                  ></Uploader>
                </div>
              </div>
            </div>
            <!-- END -->
            <div class="flex justify-center items-center mt-10">
              <AppButton
                type="button"
                class="btn btn-primary w-24 cursor-pointer text-white"
                :class="!saveLoading ? 'w-24' : ''"
                :disabled="saveLoading || findLoading"
                :loading="saveLoading"
                @click="doSubmit()"
              >
                <strong>{{ i18n('common.save') }}</strong>
                <template v-slot:loading>
                  <app-i18n code="common.loading"></app-i18n>
                  <LoadingIcon
                    icon="three-dots"
                    color="#FFFFFF"
                    style="margin: 0 4px"
                  />
                </template>
              </AppButton>
              <button
                type="button"
                class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                @click="doCancel"
              >
                {{ i18n('common.cancel') }}
              </button>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.chefs')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.chefs')
    },
    'model.categoriesIds': function(newVal, oldVal) {
      if (newVal.length > 3) {
        this.model.categoriesIds = oldVal
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      model: null,
      errorMessage: null,
      kitchenCategories: []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'user/view/kitchen',
      findLoading: 'user/view/kitchenLoading',
      saveLoading: 'user/view/kitchenSaveLoading',
      categories: 'category/list/categories'
    }),
    storagePath() {
      return `chefs/${this.id}/kitchen`
    },
    isEditing() {
      return !!this.id
    },
    filesToSaveCover() {
      return this.$refs.uploaderCover?.filesToSave || []
    },
    filesToSaveLogo() {
      return this.$refs.uploaderLogo?.filesToSave || []
    },
    logoUrl() {
      return this.model && this.model.logo ? this.model.logo : null
    },
    coverUrl() {
      return this.model && this.model.cover ? this.model.cover : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
      await this.listAllCategories()
    }
    this.model = this.record || {
      kitchenName: {
        en: '',
        ar: ''
      },
      minOrderAmount: '',
      schedule: {
        saturday: {
          working: false,
          startTime: '',
          endTime: ''
        },
        sunday: {
          working: false,
          startTime: '',
          endTime: ''
        },
        monday: {
          working: false,
          startTime: '',
          endTime: ''
        },
        tuesday: {
          working: false,
          startTime: '',
          endTime: ''
        },
        wednesday: {
          working: false,
          startTime: '',
          endTime: ''
        },
        thursday: {
          working: false,
          startTime: '',
          endTime: ''
        },
        friday: {
          working: false,
          startTime: '',
          endTime: ''
        }
      },
      logo: '',
      cover: ''
    }

    this.kitchenCategories = this.categories

    if (this.isEditing) {
      for (const day in this.model.schedule) {
        const startTime = this.model.schedule[day].startTime
        const endTime = this.model.schedule[day].endTime
        if (this.model.schedule[day].working) {
          this.model.schedule[day].startTime = this.convertTime(
            startTime,
            this.record.timeZone
          )
          this.model.schedule[day].endTime = this.convertTime(
            endTime,
            this.record.timeZone
          )
        }
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'user/view/doFindKitchen',
      doUpdate: 'user/view/doUpdateKitchen',
      listAllCategories: 'category/list/listAllCategories'
    }),
    convertTime(time, timezone) {
      const formattedTime = time
        .split(':')
        .map(num => num.padStart(2, '0'))
        .join(':')
      const date = new Date(`1970-01-01T${formattedTime}Z`)
      // const [hours, minutes] = timezone.split(':').map(Number)
      // date.setHours(date.getHours() + hours)
      // date.setMinutes(date.getMinutes() + minutes)
      date.setHours(date.getHours() + timezone)
      return date.toISOString().substr(11, 8)
    },
    resetField(key) {
      this.model.schedule[key].startTime = ''
      this.model.schedule[key].endTime = ''
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterDayLocalize(day) {
      const daysLocalized = {
        saturday: 'السبت',
        sunday: 'الأحد',
        monday: 'الاثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة'
      }
      return this.isRTL ? daysLocalized[day] : day
    },
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    isFormValid() {
      if (
        this.model.kitchenName.en.length == 0 ||
        this.model.kitchenName.ar.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.minOrderAmount.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      return true
    },
    async doSubmit() {
      const { kitchenName, minOrderAmount, schedule } = this.model

      try {
        if (this.isEditing) {
          const convertedSchedule = {}
          for (const day in schedule) {
            const startTime = schedule[day].startTime
            const endTime = schedule[day].endTime
            convertedSchedule[day] = {}
            convertedSchedule[day].working = schedule[day].working
            if (convertedSchedule[day].working) {
              convertedSchedule[day].startTime = this.convertTime(
                startTime,
                -this.record.timeZone
              )
              convertedSchedule[day].endTime = this.convertTime(
                endTime,
                -this.record.timeZone
              )
            } else {
              convertedSchedule[day].startTime = ''
              convertedSchedule[day].endTime = ''
            }
          }
          await this.doUpdate({
            id: this.id,
            kitchenName,
            timeZone: this.record.timeZone,
            categoriesIds: this.record.categoriesIds,
            schedule: convertedSchedule,
            minOrderAmount: parseFloat(minOrderAmount),
            logo: this.logoUrl || this.filesToSaveLogo[0] || null,
            cover: this.coverUrl || this.filesToSaveCover[0] || null
          })
        }
      } catch (error) {
        Message.error(error)
      }
    },
    doCancel() {
      this.$router.back()
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style lang="scss">
.dark .tom-select .ts-input {
  background-color: #424242 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
<style lang="scss" scoped>
input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 3rem;
  height: 1.5rem;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: rgb(20, 46, 113);
}

input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

label:active:after {
  width: 130px;
}
</style>
